import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { CartProvider } from "../CartContext";

import PrivateRoute from "./PrivateRoute";
import Home from "../components/Home/Index";
import Products from "../components/Home/Products";
import CheckOut from "../components/Home/CheckOut";

const Router = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/checkout" element={<CheckOut />} />

          </Route>
        </Routes>
        <Routes>
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/register" element={<Register />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
