// import { createSlice } from "@reduxjs/toolkit";

// const cartSlice = createSlice({
//   name: "cart",
//   initialState: [],
//   reducers: {
//     addToCart(state, action) {
//       state.push(action.payload);
//     },
//     removeFromCart(state, action) {
//       const id = action.payload.id;

//       const index = state.findIndex((item) => item.id === id);

//       state = state.slice(0, id).concat(state.slice(id + 1));
//     },
//     clearCart(state, action) {
//       state = [];
//     },
//   },
// });

// export default cartSlice.reducer;
// export const { addToCart } = cartSlice.actions;

// slice/cartSlice.js
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  cartItems: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const existingItem = state.cartItems.find(
        (item) => item._id === action.payload._id
      );

      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.cartItems.push({ ...action.payload, quantity: 1 });
      }
    },
    removeFromCart: (state, action) => {

      const existingItem = state.cartItems.find(
        (item) => item._id === action.payload._id
      );

      if (existingItem) {
        if (existingItem.quantity > 1) {
          existingItem.quantity -= 1;
        } else {
          state.cartItems = state.cartItems.filter(
            (item) => item._id !== action.payload._id
          );
        }
      }
    },
    increaseQuantityById: (state, action) => {
      const itemToIncrease = state.cartItems.find(
        (item) => item._id === action.payload._id
      );

      if (itemToIncrease) {
        itemToIncrease.quantity += 1;
      }
    },
    decreaseQuantityById: (state, action) => {
      const itemToDecrease = state.cartItems.find(
        (item) => item._id === action.payload._id
      );

      if (itemToDecrease && itemToDecrease.quantity > 1) {
        itemToDecrease.quantity -= 1;
      } else if (itemToDecrease) {
        state.cartItems = state.cartItems.filter(
          (item) => item._id !== action.payload._id
        );
      }
    },
    clearCart: (state) => {
      state.cartItems = [];
    },

    
  },
});

export const {
  addToCart,
  removeFromCart,
  increaseQuantityById,
  decreaseQuantityById,
  clearCart,
} = cartSlice.actions;
export const cartReducer = cartSlice.reducer;
