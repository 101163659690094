import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  userdata: {},
};
export const userData = createSlice({
  name: "themeParkSupplierData",
  initialState,
  reducers: {
    SET_USER: (state, action) => {
      state.userdata = action.payload;
    },

    CLEAR_USER: (state, action) => {
      state.userdata = initialState.themeParkSupplierData;
    },
  },
});
export const { SET_USER, CLEAR_USER } = userData.actions;
export const userDataReducer = userData.reducer;
