export const LoginValues = {
  email: "",
  password: "",
};

export const SignUpValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  // confirmPassword: "",
};

export const billingAdressValues = {
  first_name: "",
  last_name: "",
  user_name: "",
  email: "",
  address1: "",
  address2: "",
  country: "",
  state: "",
  zip: "",
};
