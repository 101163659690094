import React from "react";
import Modal from "react-bootstrap/Modal";

const Register = (props) => {
  const { handleSubmit, setFieldValue, values, errors, touched } = props.signUpformik;

  async function routeFn() {
    props.onLoginOpen();
    props.onHide();
  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="login-modal">

        <Modal.Header>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
          <svg
            onClick={() => props.onHide()}
            className="close-img"
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 72.434 72.44"
            style={{ enableBackground: "new 0 0 72.434 72.44" }}
          >
            <path
              style={{ fill: "rgb(205, 164, 94)" }}
              d="M36.22,0C16.212,0,0,16.216,0,36.227c0,19.999,16.212,36.214,36.22,36.214
	c20.011,0,36.214-16.215,36.214-36.214C72.434,16.215,56.231,0,36.22,0z M52.058,46.82c1.379,1.424,0.953,4.078-0.959,5.932
	c-1.911,1.854-4.577,2.209-5.959,0.785l-9.027-9.295l-9.298,9.027c-1.421,1.379-4.075,0.947-5.929-0.961s-2.206-4.574-0.785-5.956
	l9.298-9.027l-9.027-9.298c-1.379-1.421-0.946-4.078,0.962-5.932c1.905-1.851,4.577-2.204,5.953-0.785l9.027,9.297l9.301-9.023
	c1.424-1.382,4.078-0.95,5.929,0.958c1.857,1.908,2.206,4.577,0.785,5.959l-9.295,9.024L52.058,46.82z"
              id="id_101"
            ></path>
          </svg>
        </Modal.Header>
        <Modal.Body>
          <section className="signup-section">
            <div className="smooth signup hide-view" id="signup">
              <h1 className="signup__header header">Create a new account</h1>
              <p className="signup__byline">
                You can use your favourite social network
              </p>
              {/* <div className="social-media__container">
                <span className="fa-stack fa-lg social-media__icon icon">
                  <i
                    className="fas fa-circle fa-stack-2x"
                    style={{ color: "#48556D" }}
                  ></i>
                  <i className="fab fa-facebook-f fa-stack-1x fa-inverse"></i>
                </span>
                <span className="fa-stack fa-lg social-media__icon icon">
                  <i
                    className="fas fa-circle fa-stack-2x"
                    style={{ color: "#DF4D3B" }}
                  ></i>
                  <i className="fab fa-google-plus-g fa-stack-1x fa-inverse"></i>
                </span>
                <span className="fa-stack fa-lg social-media__icon icon">
                  <i
                    className="fas fa-circle fa-stack-2x"
                    style={{ color: "#48556D" }}
                  ></i>
                  <i className="fab fa-linkedin-in fa-stack-1x fa-inverse"></i>
                </span>
              </div> */}
              <fieldset className="form">
                {/* <legend className="form__legend">OR</legend> */}
                <form
                  onSubmit={handleSubmit}
                  // action=""
                  className="form__body form-login"
                >
                  <div className="input__group">
                    <div className="input-feilds">
                      <input
                        className="form__input form__input-half"
                        type="text"
                        placeholder="first name"
                        name="first_name"
                        value={values.first_name}
                        onChange={(e) => {
                          setFieldValue("first_name", e.target.value.trim());
                        }}
                      />
                      {errors.first_name && touched.first_name && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.first_name}
                        </span>
                      )}
                    </div>

                    <div className="input-feilds">
                      <input
                        className="form__input form__input-half"
                        type="text"
                        placeholder="last name"
                        name="last_name"
                        value={values.last_name}
                        onChange={(e) => {
                          setFieldValue("last_name", e.target.value.trim());
                        }}
                      />
                      {errors.last_name && touched.last_name && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.last_name}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="input__group">
                    <div className="input-feilds">
                      <input
                        className="form__input form__input-half"
                        type="email"
                        placeholder="email"
                        name="email"
                        value={values.email}
                        onChange={(e) => {
                          setFieldValue("email", e.target.value.trim());
                        }}
                      />
                      {errors.email && touched.email && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.email}
                        </span>
                      )}
                    </div>
                    <div className="input-feilds">
                      <input
                        className="form__input form__input-half"
                        type="password"
                        placeholder="password"
                        name="password"
                        value={values.password}
                        onChange={(e) => {
                          setFieldValue("password", e.target.value.trim());
                        }}
                      />
                      {errors.password && touched.password && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.password}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="input__group ">
                    <input className="form__input-checkbox" type="checkbox" /> I
                    have read the{" "}
                    <a className="href-color" href="#">
                      terms and conditions
                    </a>
                  </div>
                  <button className="btn" type="submit">
                    Sign up
                  </button>
                  <p className="haveAn-text">
                    You have an account?{" "}
                    <strong>
                      {" "}
                      <a
                        className="href-color"
                        rel="noopener noreferrer"
                        onClick={() => routeFn()}
                      >
                        Sign In
                      </a>
                    </strong>
                  </p>
                </form>
              </fieldset>
            </div>
          </section>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button variant="secondary" onClick={props?.onHide()}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={props?.onHide()}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default Register;
