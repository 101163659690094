import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";
// import { userReducer } from "./slice/userSlice.js";
import { cartReducer } from "./slices/CartSlice";
import { userDataReducer } from "./slices/userSlice.js";

const rootReducer = combineReducers({
  // user: userReducer,
  cart: cartReducer,
  user:userDataReducer
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: true,
});
