import React, { useEffect, useState } from "react";
import Minus from "../../assets/minus.svg";
import Plus from "../../assets/plus.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  decreaseQuantityById,
  increaseQuantityById,
  removeFromCart,
} from "../../redux/slices/CartSlice";
import { Link } from "react-router-dom";
import img1 from "../../assets/img1.png";
import Img2 from "../../assets/img2.png";
import Img3 from "../../assets/img3.png";
import Img4 from "../../assets/img4.png";
const Products = () => {
  const [data, setData] = useState([]);

  const { cartItems } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const handleIncreaseQuantity = (item) => {
    dispatch(increaseQuantityById(item));
  };

  const handleDecreaseQuantity = (item) => {
    dispatch(decreaseQuantityById(item));
  };

  const handleRemoveFromCart = (item) => {
    dispatch(removeFromCart(item));
  };

  const getData = () => {
    setData(cartItems);
  };

  useEffect(() => {
    getData();
  }, [cartItems]);

  const totalCount = (price, quantity) => {
    return parseInt(price * quantity);
  };

  const calculateSubtotal = () => {
    let subtotal = 0;
    cartItems.forEach((item) => {
      subtotal += totalCount(item.price, item.quantity);
    });
    return subtotal;
  };

  return (
    <>
      <div className="small-container cart-page">
        <div className="container">
          <div className="text-center heading-products">
            <h2 className="heading-top">My Cart </h2>
          </div>
          <div className="row">
            {Array.isArray(cartItems) && cartItems.length > 0 ? (
              <>
                <table>
                  {Array.isArray(cartItems) &&
                    cartItems.length > 0 &&
                    cartItems.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <div className="cart-info">
                            <div className="cart-info-img">
                              {/* Use the actual image source */}
                              <img
                                // src={`${process.env.REACT_APP_Upload_url}/${item?.itemImg}`}
                                src={item?.image}

                                alt=""
                              />
                            </div>
                            <div className="product-price-list">
                              <p>{item.title}</p>
                              <small>Price ₹{item.price}.00</small>
                              <br />
                              <button
                                type="button"
                                className="remove-btn"
                                onClick={() => dispatch(removeFromCart(item))}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </td>
                        <td className="quantity-td">
                          <div className="quantity-item">

                            <svg viewBox="0 0 50 50"  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"  enable-background="new 0 0 50 50">
                              <path d="M40,23.99H10c-0.552,0-1,0.447-1,1s0.448,1,1,1h30c0.552,0,1-0.447,1-1S40.552,23.99,40,23.99z" />
                            </svg>

                            {/* <img
                              src={Minus}
                              alt=""
                              onClick={() => handleDecreaseQuantity(item)}
                            /> */}
                            


                            <input
                              type="number"
                              value={item.quantity}
                              disabled
                            />
                   <svg viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus">
                   <line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                            

                            {/* <img
                              className="plus"
                              src={Plus}
                              alt=""
                              onClick={() => handleIncreaseQuantity(item)}
                            /> */}
                          </div>
                        </td>
                        <td className="amt-texts"> ₹{totalCount(item?.price, item?.quantity)}</td>
                      </tr>
                    ))}
              </table>
            <div className="total-price">
              <table>
                <tr className="pt-3 subtotal-tr">
                  <td>Subtotal</td>
                  <td>₹{calculateSubtotal()}.00</td>
                </tr>
                {/* <tr>
                  <td>Tax</td>
                  <td>₹15.00</td>
                </tr> */}
                <tr className="total-amt-tr">
                  <td>Total</td>
                  <td className="total-amts"> ₹{calculateSubtotal()}.00</td>
                </tr>
                <tr>
                  {/* <td>Total</td> */}
                  <td>
                    <Link to="/checkout" className="submit-btn">
                      Proceed To Checkout{" "}
                    </Link>
                  </td>
                </tr>
              </table>
            </div>
          </>
          ) : (
          <div className="text-center">
            <h5>No Products Found</h5>
          </div>
            )}

          {/* <div className="coupon-section">
              <div className="coupon-left">
                <input
                  type="text"
                  className="coupon-code"
                  placeholder="Coupon Code"
                />
                <button type="button" className="aplly-btn">
                  Apply Coupon
                </button>
              </div>
              <div className="coupon-right">
                <button type="button" className="update-btn">
                  Update Table
                </button>
              </div>
            </div> */}
        </div>
      </div>
    </div >
    </>
  );
};

export default Products;
