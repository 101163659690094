import { useFormik } from "formik";
import React from "react";
import Select from "react-select";

import Accordion from "react-bootstrap/Accordion";

import Alert from "react-bootstrap/Alert";
import {
  LoginValues,
  SignUpValues,
  billingAdressValues,
} from "../../formik/Values";
import {
  LoginSchema,
  SignUpSchema,
  billingAddressSchema,
} from "../../formik/Schema";
import { City, Country, State } from "country-state-city";
import { Link } from "react-router-dom";
import Login from "../../modals/login";
import Register from "../../modals/register";
import toast from "react-hot-toast";
import { SET_USER } from "../../redux/slices/userSlice";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearCart } from "../../redux/slices/CartSlice";

const CheckOut = () => {
  const navigate = useNavigate();
  const [loginModalShow, setLoginModalShow] = React.useState(false);
  const [signUpModalShow, setSignUpModalShow] = React.useState(false);
  const dispatch = useDispatch();
  const { userdata } = useSelector((state) => state.user);
  const { cartItems } = useSelector((state) => state.cart);

  const token = localStorage.getItem("authToken");

  const billingFormik = useFormik({
    initialValues: billingAdressValues,
    validationSchema: billingAddressSchema,
    onSubmit: async (data) => {
      const payloa = {
        ...data,
        items: cartItems,
      };
      if (userdata) {
        const headerData = { Authorization: `Bearer ${token}` };
        const bookingresponse = await axios.post(
          `${process.env.REACT_APP_Backend_url}/frontend/booking/addtocart`,
          payloa,
          {
            headers: headerData,
          }
        );

        console.log(bookingresponse, "bookingresponse");

        if (bookingresponse?.status == 201) {
          toast.success("Booking Successful");
          dispatch(clearCart([]));
          navigate("/");
        } else {
          toast.error("something went wrong");
        }
      } else {
        handleLoginOpen();
      }
    },
  });

  const handleLoginOpen = () => {
    setLoginModalShow(true);
  };
  const handleSignUpOpen = () => {
    setSignUpModalShow(true);
  };

  const signInformik = useFormik({
    initialValues: LoginValues,
    validationSchema: LoginSchema,
    onSubmit: async (data) => {
      const response = await axios.post(
        `${process.env.REACT_APP_Backend_url}/frontend/auth/login`,
        data
      );
      if (response.status != 200) {
        toast.error(response?.data?.message);
      } else {
        toast.success(response?.data?.message);
        dispatch(SET_USER(response?.data?.data?.user));

        localStorage.setItem("authToken", response?.data?.data?.token);

        setLoginModalShow(false);
        setSignUpModalShow(false);
      }
    },
  });

  const signUpformik = useFormik({
    initialValues: SignUpValues,
    validationSchema: SignUpSchema,
    onSubmit: async (data) => {
      const response = await axios.post(
        `${process.env.REACT_APP_Backend_url}/frontend/auth/signup`,
        data
      );

      if (response.status != 201) {
        toast.error(response.data?.message);
      } else {
        toast.success(response.data?.message);

        handleLoginOpen();
      }
    },
  });

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    setFieldTouched,
    touched,
  } = billingFormik;

  const countryOptions = () => {
    return Country.getAllCountries()?.map((v) => {
      return { label: v.name, value: v.isoCode };
    });
  };
  const stateOptions = () => {
    return State.getStatesOfCountry(values.country)?.map((v) => ({
      label: v.name,
      value: v.isoCode,
    }));
  };

  function countryValue() {
    const countries = countryOptions();
    const find = countries.find((option) => option.value === values.country);
    return find ? find : null;
  }

  function stateValue() {
    const states = stateOptions();
    const find = states.find((option) => option.value === values.state);
    return find ? find : null;
  }

  const totalCount = (price, quantity) => {
    return parseInt(price * quantity);
  };

  const calculateSubtotal = () => {
    let subtotal = 0;
    cartItems.forEach((item) => {
      subtotal += totalCount(item.price, item.quantity);
    });
    return subtotal;
  };

  console.log(cartItems, "cartItems");
  return (
    <>
      <section className="checkout-section">
        <div className="container ">
          <div className=" text-center heading-products">
            <h2 className="heading-top" id="heading-top-checkout">Checkout </h2>
            {/* <p className="lead">Below is an example form built entirely with Bootstrap’s form controls. Each required form group has a validation state that can be triggered by attempting to submit the form without completing it.</p> */}

            {/* <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="accordion-header">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path d="M12 3.2c-4.8 0-8.8 3.9-8.8 8.8 0 4.8 3.9 8.8 8.8 8.8 4.8 0 8.8-3.9 8.8-8.8 0-4.8-4-8.8-8.8-8.8zm0 16c-4 0-7.2-3.3-7.2-7.2C4.8 8 8 4.8 12 4.8s7.2 3.3 7.2 7.2c0 4-3.2 7.2-7.2 7.2zM11 17h2v-6h-2v6zm0-8h2V7h-2v2z"></path>
                  </svg>
                  Have a coupon?{" "}
                  <a className="accordion-span">
                    Click here to enter your code
                  </a>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="accordion-section">
                    <p>If you have a coupon code, please apply it below.</p>
                    <div className="accordion-left">
                      <input
                        type="text"
                        className="accordion-code"
                        placeholder="Coupon Code"
                      />
                      <button type="button" className="aplly-accord-btn">
                        Apply Coupon
                      </button>
                    </div>
                    <div className='coupon-right'>
                                        <button type='button' className='update-btn'>Update Table</button>
                                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
          </div>

          <div className="row" id="checkout-row">
            <div className="col-md-4 order-md-2 mb-4">
              <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Your cart</span>
                <span className="badge badge-secondary badge-pill">3</span>
              </h4>

              {/* <ul className="list-group mb-3">
                            <li className="list-group-item d-flex justify-content-between lh-condensed">
                                <div>
                                    <h6 className="my-0">Product name</h6>
                                    <small className="text-muted">Brief description</small>
                                </div>
                                <span className="text-muted">$12</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-condensed">
                                <div>
                                    <h6 className="my-0">Second product</h6>
                                    <small className="text-muted">Brief description</small>
                                </div>
                                <span className="text-muted">$8</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-condensed">
                                <div>
                                    <h6 className="my-0">Third item</h6>
                                    <small className="text-muted">Brief description</small>
                                </div>
                                <span className="text-muted">$5</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between bg-light">
                                <div className="text-success">
                                    <h6 className="my-0">Promo code</h6>
                                    <small>EXAMPLECODE</small>
                                </div>
                                <span className="text-success">-$5</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between">
                                <span>Total (USD)</span>
                                <strong>$20</strong>
                            </li>
                            <li className="list-group-item d-flex justify-content-center" id='list-group-button'>

                       
                            <button className=" continue-btn-check" type="submit">Continue to checkout</button>
                            </li>
                        </ul> */}
              <div className="total-price" id="total-price-checkout">
                <table>
                  <tr>
                    <th>Products</th>
                    <th>Subtotal</th>
                  </tr>
                  {cartItems && cartItems.length > 0 ? (
                    cartItems.map((val, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>
                            {val?.name}× {val?.quantity}
                          </td>
                          <td>₹ {totalCount(val?.price, val?.quantity)}</td>
                        </tr>
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No items in the cart</td>
                    </tr>
                  )}
                  <tr className="price-txt">
                    <td>Subtotal</td>
                    <td>₹{calculateSubtotal()}.00</td>
                  </tr>
                  <tr className="price-txt">
                    <td>Tax</td>
                    <td>₹15.00</td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td className="total-amts">₹{calculateSubtotal() + 15}.00</td>
                  </tr>
                  <tr>
                    <td>
                      <button
                        onClick={handleSubmit}
                        className="continue-btn-check"
                      >
                        Continue Checkout
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="col-md-8 order-md-1">
              <h4 className="mb-3">Billing address</h4>
              <form
                onSubmit={handleSubmit}
                className="needs-validation"
                novalidate
              >
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label for="firstName">First name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="first_name"
                      placeholder=""
                      required
                      name="first_name"
                      value={values.first_name}
                      onChange={(e) => {
                        setFieldValue("first_name", e.target.value.trim());
                      }}
                    />
                    <div className="invalid-feedback">
                      Valid first name is required.
                    </div>

                    {errors.first_name && touched.first_name && (
                      <span className="text-danger">{errors.first_name}</span>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label for="lastName">Last name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      placeholder=""
                      value={values.last_name}
                      onChange={(e) => {
                        setFieldValue("last_name", e.target.value.trim());
                      }}
                      required
                    />
                    <div className="invalid-feedback">
                      Valid last name is required.
                    </div>
                    {errors.last_name && touched.last_name && (
                      <span className="text-danger">{errors.last_name}</span>
                    )}
                  </div>
                </div>

                <div className="mb-3">
                  <label for="username">Username</label>
                  <div className="input-group">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">@</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        placeholder="Username"
                        value={values.user_name}
                        onChange={(e) => {
                          setFieldValue("user_name", e.target.value.trim());
                        }}
                        required
                      />
                    </div>
                    <div className="invalid-feedback w-100">
                      Your username is required.
                    </div>
                    {errors.user_name && touched.user_name && (
                      <span className="text-danger">{errors.user_name}</span>
                    )}
                  </div>
                </div>

                <div className="mb-3">
                  <label for="email">
                    Email <span className="text-muted">(Optional)</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={values.email}
                    onChange={(e) => {
                      setFieldValue("email", e.target.value.trim());
                    }}
                    placeholder="you@example.com"
                  />
                  <div className="invalid-feedback">
                    Please enter a valid email address for shipping updates.
                  </div>
                  {errors.email && touched.email && (
                    <span className="text-danger">{errors.email}</span>
                  )}
                </div>

                <div className="mb-3">
                  <label for="address">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    placeholder="1234 Main St"
                    value={values.address1}
                    onChange={(e) => {
                      setFieldValue("address1", e.target.value.trim());
                    }}
                    required
                  />
                  <div className="invalid-feedback">
                    Please enter your shipping address.
                  </div>
                  {errors.address1 && touched.address1 && (
                    <span className="text-danger">{errors.address1}</span>
                  )}
                </div>

                <div className="mb-3">
                  <label for="address2">
                    Address 2 <span className="text-muted">(Optional)</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="address2"
                    value={values.address2}
                    onChange={(e) => {
                      setFieldValue("address2", e.target.value.trim());
                    }}
                    placeholder="Apartment or suite"
                  />
                  {errors.address2 && touched.address2 && (
                    <span className="text-danger">{errors.address2}</span>
                  )}
                </div>

                <div className="row">
                  <div className="col-md-5 mb-3">
                    <label for="country">Country</label>
                    <Select
                      className="custom-select d-block w-100"
                      id="country"
                      name="country"
                      options={countryOptions()}
                      onChange={(selectedOption) => {
                        setFieldValue("country", selectedOption.value);
                        setFieldValue("state", "");
                      }}
                      value={countryOptions().find(
                        (option) => option.value === values.country
                      )}
                      required
                    />

                    {errors.country && touched.country && (
                      <span className="text-danger">{errors.country}</span>
                    )}
                  </div>
                  <div className="col-md-4 mb-3">
                    <label for="state">State</label>
                    <Select
                      className="custom-select d-block w-100"
                      classNamePrefix="select"
                      options={stateOptions()}
                      name="state"
                      onChange={(selectedOption) => {
                        setFieldValue("state", selectedOption.value);
                      }}
                      value={stateValue()}
                    />
                    {errors.state && touched.state && (
                      <span className="text-danger">{errors.state}</span>
                    )}
                  </div>
                  <div className="col-md-3 mb-3">
                    <label for="zip">Zip</label>
                    <input
                      type="text"
                      className="form-control"
                      id="zip"
                      placeholder=""
                      value={values.zip}
                      onChange={(e) => {
                        setFieldValue("zip", e.target.value.trim());
                      }}
                      required
                    />
                    {errors.zip && touched.zip && (
                      <span className="text-danger">{errors.zip}</span>
                    )}
                    <div className="invalid-feedback">Zip code required.</div>
                  </div>
                </div>
                <hr className="mb-4" />
              </form>
            </div>
          </div>
        </div>
      </section>
      <Login
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
        onSignUpOpen={handleSignUpOpen}
        signInformik={signInformik}
      />
      <Register
        show={signUpModalShow}
        onHide={() => setSignUpModalShow(false)}
        onLoginOpen={handleLoginOpen}
        signUpformik={signUpformik}
      />
    </>
  );
};

export default CheckOut;
