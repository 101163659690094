import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Login from "../../modals/login";
import Register from "../../modals/register";
import { useFormik } from "formik";
import { LoginValues, SignUpValues } from "../../formik/Values";
import { LoginSchema, SignUpSchema } from "../../formik/Schema";
import { Link, Router } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import Close from "../../assets/close.svg"
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_USER, SET_USER } from "../../redux/slices/userSlice";
import Logo from "../../assets/logo1.jpg"

const Header = () => {
  const [loginModalShow, setLoginModalShow] = React.useState(false);
  const [signUpModalShow, setSignUpModalShow] = React.useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeClass, setActiveClass] = useState(1);
  const dispatch = useDispatch();
  const { userdata } = useSelector((state) => state.user);

  // const history = useHistory();
  const location = useLocation();

  const handleLoginOpen = () => {
    setLoginModalShow(true);
  };
  const handleSignUpOpen = () => {
    setSignUpModalShow(true);
  };
  const signInformik = useFormik({
    initialValues: LoginValues,
    validationSchema: LoginSchema,
    onSubmit: async (data) => {
      const response = await axios.post(
        `${process.env.REACT_APP_Backend_url}/frontend/auth/login`,
        data
      );
      if (response.status != 200) {
        toast.error(response?.data?.message);
      } else {
        toast.success(response?.data?.message);
        dispatch(SET_USER(response?.data?.data?.user));

        localStorage.setItem("authToken", response?.data?.data?.token);

        setLoginModalShow(false);
        setSignUpModalShow(false);
      }
    },
  });

  const signUpformik = useFormik({
    initialValues: SignUpValues,
    validationSchema: SignUpSchema,
    onSubmit: async (data) => {
      const response = await axios.post(
        `${process.env.REACT_APP_Backend_url}/frontend/auth/signup`,
        data
      );

      if (response.status != 201) {
        toast.error(response.data?.message);
      } else {
        toast.success(response.data?.message);

        handleLoginOpen();
      }
    },
  });

  useEffect(() => {
    const active = localStorage.getItem("activeClass");
    if(active){
      setActiveClass(active);
    }
  }, [location?.pathname]);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    // Run this code when the component mounts
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $("#main-nav").addClass("fixedd");
      } else {
        $("#main-nav").removeClass("fixedd");
      }
    });
  }, [userdata]);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    dispatch(CLEAR_USER({}));
  };

  return (
    <>
      <nav id="main-nav" className="header">
        <div className="logo">
          <a href="index.html">
            {/* <h4>Logo</h4> */}
            <img src={Logo} alt=""/>
          </a>
        </div>

{console.log(activeClass)}
        <nav className="nav">
          <Link
            to="/"
            onClick={() => localStorage.setItem("activeClass", 1)}
            className={
              activeClass == 1 ? "nav-item is-active" : "nav-item active"
            }
            active-color="#CEBB73"
          >
            Home{" "}
          </Link>
          <Link
            to="/products"
            onClick={() => localStorage.setItem("activeClass", 2)}
            className={activeClass == 2 ? "nav-item is-active" : "nav-item"}
            active-color="#CEBB73"
          >
            Products
          </Link>
          {/* <Link
            to="/collection"
            onClick={() => localStorage.setItem("activeClass", 3)}
            className={activeClass == 3 ? "nav-item is-active" : "nav-item"}
            active-color="#CEBB73"
          >
            Collections
          </Link>
          <Link
            to="/about"
            onClick={() => localStorage.setItem("activeClass", 4)}
            className={activeClass == 4 ? "nav-item is-active" : "nav-item"}
            active-color="#CEBB73"
          >
            About Us
          </Link>
          <Link
            to="/"
            onClick={() => localStorage.setItem("activeClass", 5)}
            className={activeClass == 5 ? "nav-item is-active" : "nav-item"}
            active-color="#CEBB73"
          >
            Contact
          </Link> */}
          <span className="nav-indicator"></span>
        </nav>
        <div className="icon-navs">
          <ul>
            <li>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 576 512"
                height="30"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
                style={{ color: "rgb(255, 255, 255); cursor: pointer" }}
              >
                <path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"></path>
              </svg>
            </li>

            <li id="nav-form-btn">
              {userdata ? (
                <button className="form-btn">Hello</button>
              ) : (
                <button className="form-btn" onClick={handleLoginOpen}>
                  Log in
                </button>
              )}
            </li>
            <li className="toggle-class">
              <div className="hamburger" onClick={toggleSidebar}>
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
              </div>
            </li>

            {/* This li is use after login use for profile */}
            <li>
              <Dropdown>
                <Dropdown.Toggle
                  className="react-header-dropdown"
                  id="dropdown-basic"
                >
                  <svg
                    version="1.1"
                    id="Layer_1"
                    x="0px"
                    y="0px"
                    width="512px"
                    height="512px"
                    viewBox="0 0 512 512"
                    enable-background="new 0 0 512 512"
                  >
                    <g id="user">
                      <g>
                        <path
                          d="M256.167,277.721c-55.4,0-100.471-45.071-100.471-100.471S200.767,76.779,256.167,76.779
			c55.4,0,100.471,45.071,100.471,100.471S311.567,277.721,256.167,277.721z"
                        ></path>
                      </g>
                      <g>
                        <path
                          d="M437.19,74.98C388.83,26.63,324.55,0,256.17,0S123.5,26.63,75.15,74.98S0.17,187.62,0.17,256S26.8,388.67,75.15,437.02
			C123.5,485.37,187.79,512,256.17,512s132.66-26.63,181.021-74.98C485.54,388.67,512.17,324.38,512.17,256
			S485.54,123.33,437.19,74.98z M69.31,399.37C38.75,359.63,20.55,309.9,20.55,256c0-129.92,105.7-235.62,235.62-235.62
			S491.78,126.08,491.78,256c0,53.92-18.2,103.67-48.79,143.42c-7.58-25.359-26.88-48-56.183-65.311
			c-35.407-20.92-82.02-32.439-131.24-32.439c-49.16,0-95.57,11.521-130.68,32.46C95.91,351.41,76.82,374.01,69.31,399.37z"
                        ></path>
                      </g>
                    </g>
                  </svg>{" "}
                </Dropdown.Toggle>

                <Dropdown.Menu className="header-dropdown">
                  {/* <Dropdown.Item className='active' href="#/action-1">Profile</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Setting</Dropdown.Item> */}
                  <Dropdown.Item onClick={handleLogout}>Log Out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li></li>
            {/* This li is use after login use for profile End*/}
          </ul>
        </div>
      </nav>
      <div className="side-bar" > 
        <div className="container">
          {/* <div className="content">
                <button >
                    <div></div>
                    <div></div>
                    <div></div>
                </button>
            </div> */}
          <div
            className={isCollapsed ? "sidebar " : "sidebar collapsed"}
            id="sidebar"
          >
            {/* <div id="head">
                    <p className="logo">Nasemul</p>
                </div> */}
            <ul className="list">
            <li className="sidebar-close-li" >
             <img onClick={toggleSidebar} src={Close} alt=""/>
              </li>
              <li
                onClick={() => localStorage.setItem("activeClass", 1)}
                className={activeClass == 1 ? "active mt-4" : ""}>
                <Link to="/">Home</Link>
              </li>
              <li
                onClick={() => localStorage.setItem("activeClass", 1)}
                className={activeClass == 2 ? "active" : ""}>
                <Link to="/product">Products</Link>
              </li>
              <li>
                <a>
              {userdata ? (
                <button className="form-btn ">Hello</button>
              ) : (
                <button className="form-btn w-50" onClick={handleLoginOpen}>
                  Log in
                </button>
              )}
              
              </a>
            </li>
    
              {/* <li
                onClick={() => localStorage.setItem("activeClass", 1)}
                className={activeClass == 3 ? "active" : ""}
              >
                <Link to="/collection">Collections</Link>
              </li>
              <li
                onClick={() => localStorage.setItem("activeClass", 1)}
                className={activeClass == 4 ? "active" : ""}
              >
                <Link to="/about"> About Us</Link>
              </li>
              <li
                onClick={() => localStorage.setItem("activeClass", 1)}
                className={activeClass == 5 ? "active" : ""}
              >
                <Link to="/">Contact</Link>
              </li> */}
            </ul>
            <button type="button" className="log-out-btn">Log Out</button>
          </div>
        </div>
      </div>
      <Login
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
        onSignUpOpen={handleSignUpOpen}
        signInformik={signInformik}
      />
      <Register
        show={signUpModalShow}
        onHide={() => setSignUpModalShow(false)}
        onLoginOpen={handleLoginOpen}
        signUpformik={signUpformik}
      />
    </>
  );
};

export default Header;
