import * as Yup from "yup";

export const LoginSchema = Yup.object({
  email: Yup.string()
    .required("Email address is required.")
    .matches(
      /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}(?<!\.\d)$/,
      "Must be a valid email address."
    ),
  password: Yup.string()
    .required("Password is required.")
    .min(6, "Minimun 6 characters allowed.")
    .max(16, "Maximum 16 characters allowed.")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,16}$/,
      "Must contain one number, one uppercase, one lowercase and one special character."
    ),
});

export const SignUpSchema = Yup.object({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .required("Email address is required.")
    .matches(
      /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}(?<!\.\d)$/,
      "Must be a valid email address."
    ),
  password: Yup.string()
    .required("Password is required.")
    .min(6, "Minimun 6 characters allowed.")
    .max(16, "Maximum 16 characters allowed.")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,16}$/,
      "Must contain one number, one uppercase, one lowercase and one special character."
    ),

  // confirmPassword: Yup.string(),
  // .min(6, "Minimun 6 characters allowed.")
  // .max(16, "Maximum 16 characters allowed.")
  // .matches(
  //   /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,16}$/,
  //   "Must contain one number, one uppercase, one lowercase and one special character."
  // )
  // .oneOf(
  //   [Yup.ref("password")],
  //   "New password and confirm new password must match."
  // ),
});

export const billingAddressSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  user_name: Yup.string().required("Username is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  address1: Yup.string().required("Address 1 is required"),
  address2: Yup.string(),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  zip: Yup.string().required("ZIP Code is required"),
});
