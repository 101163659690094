import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Router from './routes/Router';
import { useEffect } from 'react';

function App() {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = '../src/assets/script'; // Adjust the path as per your file structure
//     script.async = true;
//     document.body.appendChild(script);

//     return () => {
//         document.body.removeChild(script);
//     };
// }, []);



  return (
   <>

<head>
  {/* <!-- other meta tags and links --> */}
  <link rel="icon" type="image/png" href="/favicon.ico" />
  {/* <!-- other meta tags and links --> */}
</head>
   <Router/>
   <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Montserrat:200,400,700,900'/>
   <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Raleway:300,400'/>

   <link rel='stylesheet' href='https://pro.fontawesome.com/releases/v5.2.0/css/all.css'/>
<link rel='stylesheet' href='https://static.fontawesome.com/css/fontawesome-app.css'/>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.11.3/font/bootstrap-icons.min.css" integrity="sha512-dPXYcDub/aeb08c63jRq/k6GaKccl256JQy/AnOq7CAnEZ9FzSL9wSbcZkMp4R26vBsMLFYH4kQ67/bbV8XaCQ==" crossorigin="anonymous" referrerpolicy="no-referrer" />
 
 
 
   </>
  );
}

export default App;
