import React, { useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import cardImg from "../../assets/bg2.jpg";
import Reclining from "../../assets/reciling.png";
import Sleeper from "../../assets/sleeper.png";
import Sectional from "../../assets/sectional.png";
import Leather from "../../assets/leather.png";
import Chesterfield from "../../assets/Chesterfield.png";
import Chaise from "../../assets/chaise.png";
import Convertable from "../..//assets/convetable.png";
import Modular from "../../assets/modular.png";
import sofaImg from "../../assets/sofa.png";
import arrowImg from "../../assets/arrow.png";
import Img1 from "../../assets/img1.png";
import Img2 from "../../assets/img2.png";
import Img3 from "../../assets/img3.png";
import Img4 from "../../assets/img4.png";
import { useRoutes } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/slices/CartSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Home = () => {
  const navigate = useNavigate();
  const { cartItems } = useSelector((state) => state.cart);

  const [prodcutList, setProductlist] = useState([]);

  const dispatch = useDispatch();
  const items = [
    {
      id_: 1,
      title: "Sectional Sofa",
      price: "700",
      description:
        "A spacious and comfortable sectional sofa perfect for lounging with family and friends.",
      image: Sectional,
    },
    {
      id_: 2,
      title: "Leather Sofa",
      price: "800",
      description:
        "An elegant leather sofa that adds a touch of luxury to any living room.",
      image: Leather,
    },
    {
      id_: 3,
      title: "Reclining Sofa",
      price: "700",
      description:
        "Relax and unwind with this cozy reclining sofa, featuring adjustable reclining positions.",
      image: Reclining,
    },
    {
      id_: 4,
      title: "Sleeper Sofa",
      price: "800",
      description:
        "A versatile sleeper sofa that easily converts into a comfortable bed for overnight guests.",
      image: Sleeper,
    },
    {
      id_: 5,
      title: "Chesterfield Sofa",
      price: "700",
      description:
        "Classic elegance meets modern comfort with this stylish Chesterfield sofa.",
      image: Chesterfield,
    },
    {
      id_: 6,
      title: "Chaise Lounge Sofa",
      price: "800",
      description:
        "Stretch out and relax on this luxurious chaise lounge sofa, perfect for lounging.",
      image: Chaise,
    },
    {
      id_: 7,
      title: "Convertible Sofa",
      price: "700",
      description:
        "Maximize space with this versatile convertible sofa that easily transforms into a bed.",
      image: Convertable,
    },
    {
      id_: 8,
      title: "Modular Sofa",
      price: "800",
      description:
        "Create your ideal seating arrangement with this customizable modular sofa.",
      image: Modular,
    },
  ];

  const calculateItemsToShow = () => {
    if (items.length <= 3) {
      return items.length;
    } else if (items.length <= 6) {
      return 3;
    } else {
      return 3;
    }
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: calculateItemsToShow() },
    { width: 768, itemsToShow: calculateItemsToShow() },
  ];

  const handleAddtoCart = (item) => {
    console.log(item)
    dispatch(addToCart(item));
  };

  const isItemInCart = (itemId) =>
    cartItems?.some((cartItem) => cartItem._id === itemId);

  const token = localStorage.getItem("authToken");

  const fetchProduct = async () => {
    const headerData = { Authorization: `Bearer ${token}` };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Backend_url}/admin/item/get`,
        {
          headers: headerData,
        }
      );

      setProductlist(response?.data?.data);
      if (response.status == 200) {
        return response.data.data;
      } else {
        throw new Error(response.message);
      }
    } catch (err) {
      return {
        data: "",
        response: err.message,
        status: 400,
      };
    }
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  return (
    <>
      <div className="banner-section">
        <div className="container-fluid">
          <div className="row">
            <section className="banner-left-section">
              <h2 className="loader-heading">
                {" "}
                Make Your
                <div className="loader">
                  <span className="loader-text">Interior </span>
                </div>
              </h2>
              <h2>Unique & Modern </h2>

              <button type="button" className="shop-now">
                Show Now
              </button>
            </section>
          </div>
        </div>
      </div>

      <div className="wp-block-spacer"></div>
      <section className="collection-section">
        <div className="container">
          <div className="row">
            <div
              className="text-center "
              id="heading-text-center"
            // style={{ borderBottom: "2px solid #222", borderTop: "2px solid #222" }}
            >
              <h2 className="title_font">Featured Collection</h2>
            </div>
            <div className="title_font-heading mb-3">
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elit purus
                faucibus, pellentesque placerat facilisi id sem sagittis litora
                cum sapien imperdiet
              </p>
              {/* <img  src={HeadingDown}/> */}
            </div>
            <Carousel
              breakPoints={breakPoints}
              // enableAutoPlay={autoPlay}
              // autoPlaySpeed={2000}
              showArrows={true}
            >
              {Array.isArray(items) &&
                items.length > 0 &&
                items?.map((item) => (
                  <div className="col-lg-4 mb-5" id="menu">
                    <div className="woods_card">
                      <div className="mb-3 woods_content">
                        <img
                          // src={`${process.env.REACT_APP_Upload_url}/${item?.itemImg}`}
                          src={item?.image}

                          alt=""
                        />
                      </div>
                      <div className="food_details px-3 pb-2">
                        <div className="common_flexing">
                          <h2 className="medium_font" id="heading-name">
                            {item?.name}
                          </h2>
                          <h2 className="small_font" id="price-text">
                            $ {item?.price}
                          </h2>
                        </div>
                        {console.log(item)}
                        <div className="addtpcart-div">
                          <button
                            type="ghost"
                            className="cart_btn"
                            onClick={() => {
                              handleAddtoCart(item);
                            }}
                            disabled={cartItems?.some(
                              (cartItem) => cartItem?._id === item?._id
                            )}
                          >
                            Add to cart
                          </button>
                        </div>
                        {isItemInCart(item?._id) && (
                          <div className="addtpcart-div" onClick={() => localStorage.setItem("activeClass", 2)}
                          >
                            <a
                              // type="ghost"
                              className="view-cart-btn"
                              onClick={() => navigate("/products")}
                            >
                              View Cart
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      </section>

      <div className="wp-block-spacer"></div>

      <div className="highlight-section">
        <div className="container-fluid">
          <div className="row">
            <section className="highlight-left-section">
              <h2 className="hightlight-content">World Best Sofas </h2>
              <h2 className="sale-heading"> Sale Ends In 1 Day </h2>
              <button type="button" className="order-now">
                Order Now
              </button>
              <img className="arrow-img" src={arrowImg} alt="" />
            </section>
            <section className="highlight-right-section">
              <img src={sofaImg} alt="" />
            </section>
          </div>
        </div>
      </div>

      <div className="wp-block-spacer"></div>
      <section className="category-section">
        <div className="container">
          <div className="row">
            <div
              className="text-center "
              id="heading-text-center"
            // style={{ borderBottom: "2px solid #222", borderTop: "2px solid #222" }}
            >
              <h2 className="title_font">Featured Categories</h2>
            </div>
            <div className="title_font-heading mb-3">
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elit purus
                faucibus, pellentesque placerat facilisi id sem sagittis litora
                cum sapien imperdiet
              </p>
              {/* <img  src={HeadingDown}/> */}
            </div>
            <div className="col-lg-3 mb-5">
              <div className="category_card">
                <div className="mb-3 category_content">
                  <img src={Img1} alt="" />
                </div>
                <div className="px-3 pb-2">
                  <h2 className="category-heading-name">Chair</h2>
                </div>
              </div>
            </div>

            <div className="col-lg-3 mb-5">
              <div className="category_card">
                <div className="mb-3 category_content">
                  <img src={Img2} alt="" />
                </div>{" "}
                <div className="px-3 pb-2">
                  <h2 className="category-heading-name">Night Stand</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-5">
              <div className="category_card">
                <div className="mb-3 category_content">
                  <img src={Img3} alt="" />
                </div>
                <div className="px-3 pb-2">
                  <h2 className="category-heading-name">Cylender</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-5">
              <div className="category_card">
                <div className="mb-3 category_content">
                  <img src={Img4} alt="" />
                </div>
                <div className="px-3 pb-2">
                  <h2 className="category-heading-name">Sofa</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="wp-block-spacer"></div>
    </>
  );
};

export default Home;
